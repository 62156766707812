import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const demoValues = [
  "YA Fiction - 1st Person - LGBTQ",
  "Childrens Fiction - 3rd Person - Child",
  "Nonfiction - Self Help",
  "Fiction - 3rd Person",
  "Science Fiction - 1st Person - Female-Female Dialogue - Filipino Accent",
  "Fiction - 3rd Person - Multiple Males and Females - Texan Accent",
  "Romance - 3rd Person - Male-Female Dialogue - Australian Accent",
  "YA Fiction - 3rd Person - Male-Female Dialogue - Mexican Accent",
]

function DemoPlayer(n) {
  const srcString = "/Demos/" + n + ".mp3"
  return (
    <div>
      <div className='demo-title'>{n}</div>
      <AudioPlayer
        src={srcString}
        showJumpControls={false}
        customAdditionalControls={[]}
      />
    </div>
  )
}

export default function Demos () {
  return(
    <div>
      <div className='section-title'>Demos</div>
      <div className='demos'>
        <div className='row'>
          <div className='col'>
            {demoValues.slice(0,4).map(n => DemoPlayer(n))}
          </div>
          <div className='col'>
            {demoValues.slice(4).map(n => DemoPlayer(n))}
          </div>
        </div>
      </div>
    </div>
  )
}

