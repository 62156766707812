export default function Header () {
  return(
    <div className="header">
      DIWA REYES
      <div className="subtitle">
        <div>Audiobook Narrator</div>
      </div>
      <div className="subtitle-email">
        <a href="mailto:hello@diwareyes.com">hello@diwareyes.com</a>
      </div>
    </div>
  )
}