import './App.css';
import Header from './Components/Header';
import Intro from './Components/Intro';
import Demos from './Components/Demos';
import Recent from './Components/Recent';

function App() {
  return (
    <div className="App">
      <Header />
      <Intro />
      <Demos />
      <Recent />
    </div>
  );
}

export default App;
