export default function Recent () {
  const recentlyRecorded = [
    [
      "Letters to a Writer of Color",
      "(On the Inactive Protagonist by Vida Cruz-Borja)",
      "https://www.audible.com/pd/Letters-to-a-Writer-of-Colour-Audiobook/B0BWZBFB52",
      "/CoverArt/Letters to a Writer of Color Cover Art.jpg"
    ],
    [
      "Brood Mother (The Gryphonpike Chronicles, Book 5) ",
      "by Annie Bellet",
      "https://www.audible.com/pd/Brood-Mother-Audiobook/B0C9G8DCQZ",
      "/CoverArt/Brood Mother Cover Art.jpg"
    ],
    [
      "Into The North (The Gryphonpike Chronicles, Book 6)",
      "by Annie Bellet",
      "https://www.audible.com/pd/Into-the-North-Audiobook/B0C9RS51XJ",
      "/CoverArt/Into The North.jpg"
    ],
    [
      "From Earth and Bone (A Ramos Sisters Thriller)",
      "by Jessie Kwak",
      "https://www.audible.com/pd/From-Earth-and-Bone-Audiobook/B0CBT3B596",
      "/CoverArt/From Earth and Bone Cover Art.jpg"
    ],
    [
      "Chaos Zone (Parse Galaxy, Book 1)",
      "by Kate Sheeran Swed",
      "https://www.audible.com/pd/Chaos-Zone-Audiobook/B0CKG3PRVH",
      "/CoverArt/Chaos Zone Cover Art.jpg"
    ]
  ]

  function book(title, author, link, source) {
    return(
      <div className="recent-book">
        <div className="book-info">
          <div><a href={link}>{title}</a></div>
          <div><a href={link}>{author}</ a></div>
        </div>
        <img className="cover-art" src={source} alt=""/>
      </div>
    )
  }


  return(
    <div className="recent">
      <div className="section-title">Recently Recorded Titles</div>
      <div className="recent-list">
        {recentlyRecorded.map(([title, author, link, source]) => book(title, author, link, source))}
      </div>
    </div>
  )
}